export const SET_BREAKPOINT = 'setBreakpoint';
export const SET_TOKEN_PRICE = 'setTokenPrice';
export const SET_CURRENCY = 'setCurrency';
export const SET_FRACTION_DIGITS = 'setFractionDigits';
export const SET_DEFI_SLIPPAGE_RESERVE = 'setDefiSlippageReserve';
export const SET_FUNISWAP_SLIPPAGE_TOLERANCE = 'setFUniswapSlippageTolerance';
export const SET_DARK_MODE = 'setDarkMode';
export const SET_AUTO_DARK_MODE = 'setAutoDarkMode';
export const SET_RTL_DIR = 'setRTLDir';
export const SET_ACTIVE_ACCOUNT_BY_ADDRESS = 'setActiveAccountByAddress';
export const SET_ACTIVE_ACCOUNT_ADDRESS = 'setActiveAccountAddress';
export const SET_SEND_DIRECTION = 'setSendDirection';
export const DEACTIVATE_ACTIVE_ACCOUNT = 'deactiveActiveAccount';
export const APPEND_ACCOUNT = 'appendAccount';
export const REMOVE_ACTIVE_ACCOUNT = 'removeAccount';
export const SET_ACCOUNT = 'setAccount';
export const MOVE_ACCOUNT = 'moveAccount';
export const PUSH_BNBRIDGE_PENDING_REQUEST = 'pushBnbbridgePendingRequests';
export const SHIFT_BNBRIDGE_PENDING_REQUEST = 'shiftBnbbridgePendingRequests';
export const REMOVE_BNBRIDGE_PENDING_REQUEST = 'removeBnbbridgePendingRequests';
export const APPEND_CONTACT = 'appendContact';
export const MOVE_CONTACT = 'moveContact';
export const REMOVE_CONTACT = 'removeContact';
export const SET_CONTACT = 'setContact';
export const SET_TX_FEE = 'setTxFee';
