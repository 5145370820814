<template>
    <div class="f-network-status not-visible">
        <f-window
            ref="popover"
            popover
            :with-header="false"
            animation-in="scale-center-enter-active"
            animation-out="scale-center-leave-active"
            class="f-network-status-window"
        >
            Api is unavailable, trying to reconnect
        </f-window>
    </div>
</template>

<script>
import FWindow from '@/components/core/FWindow/FWindow.vue';
import FNetworkStatus from '@/components/core/FNetworkStatus/FNetworkStatus.vue';

/**
 * Requires apollo-network-status plugin.
 */
export default {
    name: 'FApiStatus',

    components: { FWindow },

    data() {
        return {
            status: '',
        };
    },

    mounted() {
        this.$apolloNetworkStatus.addCallback(this.onNetworkStatus);
    },

    beforeDestroy() {
        this.$apolloNetworkStatus.removeCallback(this.onNetworkStatus);
    },

    methods: {
        onNetworkStatus(_status) {
            this.status = _status;

            if (_status === 'offline') {
                if (FNetworkStatus.getNetworkStatus() === 'online') {
                    this.$refs.popover.show();
                }
            } else {
                this.$refs.popover.hide();
            }
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
