<template>
    <div class="view-settings std-view">
        <main class="main">
            <h1>Settings</h1>

            <h2>PWA Wallet</h2>
            <settings-form />

            <h2>fUNI</h2>
            <f-uniswap-settings />

            <h2>Metamask</h2>
            <m-m-settings />

            <h2>Links</h2>
            <settings-links />
        </main>
        <Footer />
    </div>
</template>

<script>
import SettingsForm from '@/components/forms/SettingsForm.vue';
import MMSettings from '@/components/mm/MMSettings/MMSettings.vue';
import FUniswapSettings from '@/components/funi/FUniswapSettings/FUniswapSettings.vue';
import SettingsLinks from '@/views/Settings/SettingsLinks.vue';
import Footer from '@/components/Footer/Footer.vue';

export default {
    name: 'Settings',

    components: { Footer, SettingsLinks, FUniswapSettings, MMSettings, SettingsForm },
};
</script>

<style lang="scss">
@import 'style';
</style>
