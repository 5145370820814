<template>
    <div class="f-network-status not-visible">
        <f-window
            ref="popover"
            popover
            :with-header="false"
            animation-in="scale-center-enter-active"
            animation-out="scale-center-leave-active"
            class="f-network-status-window"
        >
            You are offline
            <!--            Network error, trying to reconnect-->
        </f-window>
    </div>
</template>

<script>
import FWindow from '@/components/core/FWindow/FWindow.vue';
import Vue from 'vue';

/** Global state for FAppTheme */
const networkStatusState = new Vue({
    data: {
        status: 'online',
    },
});

/**
 * Requires apollo-network-status plugin.
 */
export default {
    name: 'FNetworkStatus',

    components: { FWindow },

    mounted() {
        window.addEventListener('online', this.onOnline);
        window.addEventListener('offline', this.onOffline);

        if (!navigator.onLine) {
            this.onOffline();
        }
    },

    beforeDestroy() {
        window.removeEventListener('online', this.onOnline);
        window.removeEventListener('offline', this.onOffline);
    },

    methods: {
        onOffline() {
            this.$refs.popover.show();
            document.documentElement.classList.add('offline');
            networkStatusState.status = 'offline';
        },

        onOnline() {
            this.$refs.popover.hide();
            document.documentElement.classList.remove('offline');
            networkStatusState.status = 'online';
        },
    },

    getNetworkStatus() {
        return networkStatusState.status;
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
