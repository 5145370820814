import appConfig from '../../../app.config.js';
import { Web3Modal } from '@web3modal/standalone';
import UniversalProvider from '@walletconnect/universal-provider';
import { toInt } from '@/utils/big-number.js';
import { delay } from '@/utils/index.js';
import Web3 from 'web3';
import { SET_WALLETCONNECT_ACCOUNT, SET_WALLETCONNECT_CHAIN_ID } from '@/plugins/walletconnect/store.js';
import { store } from '@/store/index.js';

/** @type {WalletConnect} */
export let walletConnect = null;

const OPERA_CHAIN_ID = appConfig.chainId;
const RPC_URL = appConfig.rpc;

export class WalletConnect {
    static install(_Vue) {
        if (!walletConnect) {
            walletConnect = new WalletConnect();
            _Vue.prototype.$walletConnect = walletConnect;
        }
    }

    constructor() {
        this.selectedAddress = '';
        this.chainId = '';

        this._options = {
            projectId: 'e80a985fa3ee3d4698a4a3c727a869a4',
            defaultChainId: appConfig.chainId,
        };
        this._defaultChainId = this._options.defaultChainId;
        this._provider = null;
        this._wcProvider = null;
        this._web3Modal = null;

        // this.init();
    }

    // async init({ activateOnInit = true, address = '' } = {}) {
    async connect({ activateOnInit = true, address = '' } = {}) {
        console.log('connect', this._provider);

        await this._createClient();

        if (this._provider === null) {
            if (!(await this._checkPersistedSession())) {
                await this._connect(this._wcProvider);
            }
            // await this.#initProvider(provider, activateOnInit);
        }

        if (!activateOnInit && address) {
            this.selectedAddress = address;
        }

        return [this.selectedAddress];
    }

    async signTransaction(transaction, address) {
        if (!this._provider) {
            throw new Error('A provider is not set');
        }

        if (!transaction.from) {
            transaction.from = address;
        }

        try {
            if (!transaction.data) {
                transaction.data = '0x';
            }

            const { transactionHash } = await this._provider.eth.sendTransaction(transaction);

            return transactionHash;
        } catch (error) {
            console.error(error);

            return 'rejected';
        }
    }

    async personalSign() {
        throw new Error('Not implemented yet');
    }

    async disconnect(dontDisconnectWC) {
        if (this._wcProvider) {
            if (!dontDisconnectWC) {
                await this._wcProvider.disconnect();
            }

            this._wcProvider = null;
            this._provider = null;

            window.location.reload();
        }
    }

    isCorrectChainId(chainId) {
        return toInt(this.chainId) === (chainId || toInt(OPERA_CHAIN_ID));
    }

    async _checkPersistedSession() {
        // const pairings = this._wcProvider.client.pairing.getAll({ active: true });
        const { session } = this._wcProvider;

        if (session) {
            await this._onSessionConnected(session);

            return true;
        }

        return false;
    }

    async _onSessionConnected(session) {
        // console.log('SESSION:', session);

        const allNamespaceAccounts = Object.values(session.namespaces)
            .map((namespace) => namespace.accounts)
            .flat();

        const chainData = allNamespaceAccounts[0].split(':');
        // const caipChainId = `${chainData[0]}:${chainData[1]}`;
        // const accounts = allNamespaceAccounts.map((account) => account.split(':')[2];
        await this._setChainId(toInt(chainData[1]));
        await this._setAddress(chainData[2]);

        this._createProvider();
    }

    async _setChainId(chainId) {
        const provider = this._provider;

        await delay(100);

        if (chainId) {
            this.chainId = chainId;
        } else if (provider && provider.chainId) {
            this.chainId = provider.chainId;
        } else {
            this.chainId = this._defaultChainId;
        }

        store.commit(`walletConnect/${SET_WALLETCONNECT_CHAIN_ID}`, this.chainId);
        // console.log('chainid', chainId, this.chainId);
    }

    async _setAddress(address = '') {
        if (address) {
            this.selectedAddress = address;
            this.active = true;
            store.commit(`walletConnect/${SET_WALLETCONNECT_ACCOUNT}`, this.selectedAddress);
        }
    }

    async _connect(provider) {
        const chainId = toInt(this._options.defaultChainId);

        const session = await provider.connect({
            namespaces: {
                eip155: {
                    methods: ['eth_sendTransaction', 'personal_sign'],
                    chains: [`eip155:${chainId}`],
                    events: ['chainChanged', 'accountsChanged'],
                    rpcMap: { 250: RPC_URL, '0xfa': RPC_URL },
                },
            },
            pairingTopic: undefined,
        });

        console.log('SESSION: ', session);

        this._createProvider();
        const accounts = await provider.enable();
        console.log('ACCOUNTS', accounts);
        await this._setChainId();
        await this._setAddress(accounts[0]);

        this._web3Modal.closeModal();
    }

    async _createClient() {
        const { projectId, logger = 'debug', relayUrl = 'wss://relay.walletconnect.com' } = this._options;

        if (!projectId) {
            throw new Error('No project id provided');
        }

        this._wcProvider = await UniversalProvider.init({ projectId, logger, relayUrl });
        this._web3Modal = new Web3Modal({
            projectId,
            walletConnectVersion: 2,
            // standaloneChains: namespaces.eip155.chains,
        });

        this._subscribeToWCProviderEvents(this._wcProvider);
    }

    _subscribeToWCProviderEvents(provider) {
        provider.on('display_uri', async (uri) => {
            console.log('EVENT', 'QR Code Modal open', uri);
            await this._web3Modal.openModal({ uri });
        });

        // Subscribe to session event
        provider.on('session_event', ({ event, chainId }) => {
            console.log('EVENT', 'session_event');
            console.log(event, chainId);
        });

        // Subscribe to session update
        provider.on('session_update', ({ topic, session }) => {
            console.log('EVENT', 'session_updated', topic, session);
        });

        provider.on('session_delete', ({ id, topic }) => {
            console.log('EVENT', 'session_deleted');
            console.log(id, topic);
            this.disconnect(true);
        });
    }

    _createProvider() {
        this._provider = new Web3(this._wcProvider);
    }
}
